<template>
  <Layout>
    <Header class="header">
      <Breadcrumb class="breadcrumb">
        <BreadcrumbItem to="/sys_manage/user">系统用户</BreadcrumbItem>
        <BreadcrumbItem>关联角色</BreadcrumbItem>
      </Breadcrumb>
    </Header>
    <Content class="content">
      <Spin size="large" v-if="loading" fix></Spin>
      <div style="margin-bottom: 20px; color: #0077EE;">
        <label>用户名：</label><label>{{user.username}}</label>
        <label style="margin-left: 20px;">真实姓名：</label><label>{{user.realName}}</label>
      </div>
      <Card style="width:600px" >
        <CheckboxGroup v-model="roleNameList">
        <template v-for="item in roleList">
          <Checkbox :label="item.roleName" :key="item.id">
            <span>{{item.roleDesc}}</span>
          </Checkbox>
        </template>
        </CheckboxGroup>
        <Button style="background: #00A0E9;" class="btn" type="primary" @click="submit">保存</Button>
      </Card>
    </Content>
  </Layout>
</template>

<script>
import Vue from 'vue'
import request from '@/utils/request'
export default Vue.extend({
    data() {
        return {
          loading: true,
          userId: null,
          roleList: [],
          user: {},
          roleNameList: [],
        }
    },
    mounted() {
      this.userId = this.$route.params.id
      Promise.all([ this.getUser(), this.getRoleList(), this.getData() ])
      .then(() => this.loading = false)
      .catch(() => this.loading = false)
    },
    methods: {
      getUser() {
        return request.get(`/api/sys/user/${this.userId}`)
        .then((data) => this.user = data)
      },
      getRoleList() {
        return request.get(`/api/sys/role/list`)
        .then((data) => this.roleList = data ? data : [])
      },
      getData() {
        return request.get(`/api/sys/user/rel_role_name_list/${this.userId}`)
        .then((data) => this.roleNameList = data ? data : [])
      },
      submit() {
        const relRoleNameList = this.roleNameList
        request.put(`/api/sys/user/update_rel_role/${this.userId}`, relRoleNameList)
        .then(() => {
          this.$Message.success('关联成功!')
          this.$router.replace('/sys_manage/user')
        })
      },
    },
})
</script>

<style scoped>
  .ivu-layout {
    background: transparent;
    height: 100%;
  }
  .header{
    height: 40px;
    line-height: 40px;
    background: transparent;
  }
  ::v-deep .ivu-breadcrumb{
    font-size: 16px;
  }
  ::v-deep .ivu-breadcrumb a{
    color: #0077EE;
  }
  ::v-deep .ivu-breadcrumb-item-separator{
    color: #0077EE;
  }
  ::v-deep .ivu-breadcrumb>span:last-child{
    color: #0077EE;
  }
  .content {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
  }
  .btn {
    margin-top: 20px;
  }
 ::v-deep .ivu-card{
   background-color: transparent;
   border-color: #0077EE;
 }
 ::v-deep .ivu-checkbox-group{
   color: #0077EE;
 }
 ::v-deep .ivu-spin-fix{
   background-color: transparent;
   border-color: #0077EE;
 }
 ::v-deep .ivu-table-wrapper>.ivu-spin-fix{
   border: none;
 }
</style>
